import Router, { withRouter } from 'next/router'

export const initialState = (() => {
  return({
    currentUser: null,
    reader: null,
    paid_post: {},
    writer: {},
    loading: false,
    price_menus: [],
    dashboard_posts_tab: ['published'],
  })
})();

const reducer = (state, action) => {
  switch(action.type) {
    case 'CURRENT_USER':
      return {
        ...state,
        currentUser: action.user,
      }
    case 'CURRENT_SUBSCRIBER':
      return {
        ...state,
        currentUser: {...action.subscriber, ...{subscriber: action.subscriber} }
      }
    case 'DASHBOARD':
      return {
        ...state,
        reader: action.reader,
      }
    case 'DASHBOARD_POSTS_TAB':
      return {
        ...state,
        dashboard_posts_tab: action.dashboard_posts_tab,
      }
    case 'LOADING':
      return {
        ...state,
        loading: action.status,
      }
    case 'PAID_POST':
      let hash = {}
      hash[action.id] = action.post
      return {
        ...state,
        paid_post: {...action.paid_post, ...hash },
      }
    case 'WRITER_REGISTRATION':
      return {
        ...state,
        writer: {...state.writer, ...action.writer },
      }
    case 'UPDATE_PRICE_MENUS':
      return {
        ...state,
        price_menus: action.price_menus,
      }
    default:
      return state
  }
}
export default reducer

